((window, document) => {
  const brandIds = {
    Taxi: [2603],
    VD: [2801],
    VMC: [3163, 3164, 3269],
  };

  const url = window.location.hostname;

  function getCurrentBrand() {
    let result = '';
    if (url.includes('thetaxicentre') || url.includes('ttc.web')) {
      result = 'Taxi';
    } else if (url.includes('vansdirect') || url.includes('vd.web')) {
      result = 'VD';
    } else if (url.includes('vertumotorcycles') || url.includes('vmc.web')) {
      result = 'VMC';
    }
    return result;
  }

  function initialize() {
    let idString = '';
    const brandArr = brandIds[getCurrentBrand()];
    brandArr.forEach((value) => {
      idString = `${idString + value}|`;
    });

    let surveyIdString = '&surveyId=2';

    if (url.includes('vansdirect') || url.includes('vd.web')) {
      surveyIdString = '';
    } else if (url.includes('vertumotorcycles') || url.includes('vmc.web')) {
      surveyIdString = '';
    } else if (url.includes('thetaxicentre') || url.includes('ttc.web')) {
      surveyIdString = '&surveyId=97';
    }

    let urlString =
      'https://js-api.scdn5.secure.raxcdn.com/testimonials.jsonp?key=06b875770769debf92e44c77dc7696df7e9d00b4&dealerId=';
    urlString = `${urlString}${idString}&callback=judgeServiceCTCallback${surveyIdString}`;
    // eslint-disable-next-line no-use-before-define
    window.judgeServiceCTCallback = injectValues;
    // eslint-disable-next-line no-use-before-define
    attachScript(urlString);
  }

  function attachScript(uriString) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = uriString;
    document.body.appendChild(script);
  }

  function injectValues(data) {
    const elements = document.querySelectorAll('.js-usp-percent');
    elements.forEach((element) => {
      element.innerHTML = Math.floor(data.recommend_percent);
    });
  }

  initialize();
})(window, document);
